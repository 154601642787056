<template>
  <div class="BeneficiaireDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Mon tableau de bord</h1>
        <div class="form_div">
          <div class="form_content">

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations de votre profil,
                  veuillez vérifier les données renseignées ou nous contacter directement.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="createAccordErrorModal" id="createAccordErrorModal" hide-footer title="Erreur lors de la création de votre demande">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la création de votre demande,
                  veuillez réessayer ou nous contacter directement.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('createAccordErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="createRemboursementErrorModal" id="createRemboursementErrorModal" size="lg" hide-footer title="Erreur lors de la création de votre demande de remboursement">
              <div class="text-center">
                <p>Une erreur s'est produite lors de la création de votre demande de remboursement,
                  veuillez réessayer ou nous contacter directement.
                </p>
                <p>
                  <b>
                    Attention, votre demande de remboursement doit être effectuée dans <u>un délai de 3 mois</u> après la réalisation de la prestation.
                  </b>
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('createRemboursementErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="createRemboursementModal" hide-footer size="lg">
              <template v-slot:modal-title>
                <strong> Faire une nouvelle demande de remboursement </strong>
              </template>
              <div>
                <b-form>
                  <p>
                    Attention, la demande de remboursement pour une prestation de garde réglée doit
                    être effectuée <strong>dans un délai de 3 mois</strong> après la réalisation de la prestation de garde.
                  </p>
                  <b-form-group class="mt-2" id="create-remboursement-year-group" label="Année concernée" label-for="create-remboursement-year-input">
                    <b-form-select
                      v-model="newRemboursementYear" :options="newRemboursementYearArray"
                      id="create-remboursement-year-input"
                      @input="$v.newRemboursementYear.$touch()"
                      :state="$v.newRemboursementYear.$dirty ? !$v.newRemboursementYear.$error : null">
                    </b-form-select>
                  </b-form-group>

                  <b-form-group v-if="newRemboursementYear" class="mt-2" id="create-remboursement-month-group" label="Mois concerné" label-for="create-remboursement-month-input">
                    <b-form-select
                      v-model="newRemboursementMonth" :options="newRemboursementMonthArray"
                      id="create-remboursement-month-input"
                      @input="$v.newRemboursementMonth.$touch()"
                      :state="$v.newRemboursementMonth.$dirty ? !$v.newRemboursementMonth.$error : null">
                    </b-form-select>
                  </b-form-group>

                  <div v-if="newRemboursementMonth" role="group" class="form-group">
                    <label for="create-remboursement-children-input">Enfant(s) de moins de 4 ans concerné(s) par la garde</label>
                    <vue-multiselect-input
                      id="create-remboursement-children-input"
                      name="create-remboursement-children-input"
                      v-model="newRemboursementChildren"
                      :options="newRemboursementChildrenSelectOptions"
                      :multiple="true"
                      :group-select="true"
                      placeholder="Choisissez un ou plusieurs enfants"
                      selectLabel="Ajouter"
                      deselectLabel="Supprimer"
                      selectedLabel="Sélectionné"
                      track-by="value"
                      label="label">
                      <span slot="noResult">Aucun élément trouvé, veuillez réessayer</span>
                      <span slot="noOptions">Aucun enfant de moins de 4 ans</span>
                    </vue-multiselect-input>
                  </div>

                  <div v-if="newRemboursementMonth">
                    <span> Type(s) de garde concerné(s) </span>
                    <b-form-group class="mt-2" id="new-remboursement-newRemboursementIsCreche-group" label-for="new-remboursement-newRemboursementIsCreche-input">
                      <b-form-checkbox
                        v-model="newRemboursementIsCreche"
                        id="new-remboursement-newRemboursementIsCreche-input">
                        Crèche ou périscolaire
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group id="new-remboursement-newRemboursementIsGadPajemploi-group" label-for="new-remboursement-newRemboursementIsGadPajemploi-input">
                      <b-form-checkbox
                        v-model="newRemboursementIsGadPajemploi"
                        id="new-remboursement-newRemboursementIsGadPajemploi-input">
                        Garde à domicile / Assitante maternelle via PAJEMPLOI
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group id="new-remboursement-newRemboursementIsGadCesu-group" label-for="new-remboursement-newRemboursementIsGadCesu-input">
                      <b-form-checkbox
                        v-model="newRemboursementIsGadCesu"
                        id="new-remboursement-newRemboursementIsGadCesu-input">
                        Garde à domicile via CESU
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group id="new-remboursement-newRemboursementIsGadStructure-group" label-for="new-remboursement-newRemboursementIsGadStructure-input">
                      <b-form-checkbox
                        v-model="newRemboursementIsGadStructure"
                        id="new-remboursement-newRemboursementIsGadStructure-input">
                        Microcrèche / Garde à domicile via une structure de garde d'enfants
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group id="new-remboursement-newRemboursementIsPair-group" label-for="new-remboursement-newRemboursementIsPair-input">
                      <b-form-checkbox
                        v-model="newRemboursementIsPair"
                        id="new-remboursement-newRemboursementIsPair-input">
                          Jeune fille au pair
                      </b-form-checkbox>
                    </b-form-group>

                    <p
                      v-if="newRemboursementIsCreche"
                    >
                      <u>Attention</u>, pour le mode de garde en crèche, vous ne devez pas percevoir l'aide au complément du libre choix de mode de garde de la CAF
                    </p>
                  </div>

                  <b-button
                    block :variant="createRemboursementButtonStyle" class="mt-2"
                    @click="createRemboursement"
                    :disabled="$v.newRemboursementYear.$invalid || $v.newRemboursementMonth.$invalid || $v.newRemboursementChildren.$invalid || !isAtLeastOneTypeOfGarde || createRemboursementInProcess">
                      <b-spinner small label="create appointment Spinner" v-if="createRemboursementInProcess"></b-spinner> {{ createRemboursementButtonText }}
                  </b-button>
                </b-form>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>


            <b-tabs v-model="tabsIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-white" pills>

              <b-tab >
                <template slot="title">
                  <span class="h4">Général</span>
                </template>

                <h2>1. Votre profil <v-icon name="check-circle" class="ml-1" style="color:green"/></h2>
                <div class="row">
                  <div class="col-12 text-right">
                    <router-link to="/settings/profile-edition">
                      <small style="display: inline-flex; vertical-align: middle;">
                        <v-icon name="edit" scale="1.2"/>
                        <span class="ml-1">Editer votre profil</span>
                      </small>
                    </router-link>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <span> Votre identifiant : {{beneficiaire.user.username}}</span>
                  </div>
                  <div class="col-md-6">
                    <span> Votre mot de passe : </span> <router-link to="/settings/modify-password">**********</router-link>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span> Prénom : {{beneficiaire.user.first_name}}</span> <span v-if="beneficiaire.other_first_names">({{beneficiaire.other_first_names}})</span><br>
                  </div>
                  <div class="col-md-6">
                    <span> Nom : {{beneficiaire.user.last_name}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span> Date naissance : {{beneficiaire.birthdate}}</span>
                  </div>
                  <div class="col-md-6">
                    <span> Situation : {{situationRender(beneficiaire.situation)}}</span>
                  </div>
                </div>
                <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
                  <div class="col-md-6">
                    <span> Prénom de votre conjoint : {{beneficiaire.conjoint_first_name}}</span>
                  </div>
                  <div class="col-md-6">
                    <span> Nom de votre conjoint : {{beneficiaire.conjoint_last_name}}</span> <br>
                  </div>
                </div>
                <div v-if="beneficiaire.situation === 'MAR' || beneficiaire.situation === 'PACS' || beneficiaire.situation === 'VIEMAR'" class="row">
                  <div class="col-12">
                    <span> Votre conjoint est-il intermittent ? {{trueFalseRender(beneficiaire.is_conjoint_intermittent)}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span>Numéro de téléphone : {{beneficiaire.phone_number}}</span> <br>
                  </div>
                  <div class="col-md-6">
                    <span> Autre numéro : {{beneficiaire.other_phone_number}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <span>Adresse : {{beneficiaire.address}}, {{beneficiaire.code_postal}} {{beneficiaire.city}}</span> <br>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <span>Activité : {{activiteRender(beneficiaire.activite)}}</span> <br>
                  </div>
                  <div class="col-md-6">
                    <span> Métier : {{beneficiaire.metier}}</span>
                  </div>
                </div>

                  <!-- CHILDREN -->
                  <h2> 2. Vos enfants à charge
                    <v-icon v-if="beneficiaire.children.length == 0" name="times-circle" class="ml-1" style="color:red"/>
                    <v-icon v-if="beneficiaire.children.length > 0" name="check-circle" class="ml-1" style="color:green"/>
                  </h2>

                  <childrenComponent
                    v-bind:children="beneficiaire.children"
                    @updateChildrenList="getConnectedBeneficiaire"
                  />

              </b-tab>


              <b-tab >
                <template slot="title">
                  <span class="h4">Mes accords de principe</span>
                </template>

                <h2>{{currentYear}} -
                  <span v-if="!currentAccord">Aucun accord de principe en cours<v-icon name="times-circle" class="ml-2" style="color:red"/></span>
                  <span v-else-if="currentAccord.state == '1CREATED'" class="text-info">
                    Accord de principe en cours de création, demande de validation à faire <v-icon class="ml-1" name="edit" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '2ONGOING'" class="text-secondary">
                    En cours de validation par votre conseiller.ère Audiens <v-icon name="dot-circle" scale="1.3"/>
                  </span>
                  <span v-else-if="currentAccord.state == '4CREFUSED' || currentAccord.state == '5CVALIDATED'" class="text-secondary">
                    Pris en charge par votre conseiller.ère Audiens <v-icon name="dot-circle" scale="1.3"/>
                  </span>
                  <span v-else-if="currentAccord.state == '3INCOMPLET'" class="text-danger">
                    Dossier à compléter, veuillez corriger vos informations et réactualiser votre demande <v-icon name="times-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '3IMPOTINCOMPLET'" class="text-danger">
                    Dossier à compléter, veuillez ajouter des documents en plus de votre avis d'imposition  <v-icon name="times-circle" scale="1.4"/>
                  </span>
                  <span v-else-if="currentAccord.state == '6MREFUSED'" class="text-danger">
                    Refusé<v-icon class="ml-1" name="times-circle" scale="1"/>
                  </span>
                  <span v-else-if="currentAccord.state == '7MVALIDATED'" class="text-success">
                    Validé <v-icon name="check-circle" scale="1.4"/>
                  </span>
                </h2>
                <div v-if="!currentAccord && eligibleChildren.length > 0">
                  <p>
                    Vous n'avez pour le moment aucun accord de principe pour l'année en cours. <br>
                    <strong>Attention à cette étape vous ne pouvez pas faire de demandes de remboursement</strong>
                  </p>
                  <b-button
                    type="submit" pill block :variant="createAccordButtonStyle" class="mt-2"
                    @click="createAccord"
                    :disabled="this.createAccordInProcess">
                      <b-spinner small label="Create accord Spinner" v-if="createAccordInProcess"></b-spinner> {{ createAccordButtonText }}
                  </b-button>
                </div>
                <div v-else-if="currentAccord">
                  <div v-if="currentAccord.state == '1CREATED'">
                    <p>
                      Votre demande d'accord de principe {{currentYear}} a bien été créée.<br>
                      Vous pouvez y accéder, charger vos documents puis faire une demande de validation de votre accord
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-info" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                  <div v-else-if="currentAccord.state == '2ONGOING' || currentAccord.state == '4CREFUSED' || currentAccord.state == '5CVALIDATED'">
                    <p> Votre demande d'accord de principe {{currentYear}} est en cours de validation par votre conseiller.ère Audiens.
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-secondary" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                  <div v-else-if="currentAccord.state == '3INCOMPLET'">
                    <p>
                      Votre demande d'accord de principe {{currentYear}} a été marquée comme incomplète par votre conseiller.ère Audiens. <br>
                      <em v-if="currentAccord.refused_message"> Raisons du refus : {{currentAccord.refused_message}}</em>
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-danger" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                  <div v-else-if="currentAccord.state == '3IMPOTINCOMPLET'">
                    <p> Votre demande d'accord de principe {{currentYear}} a été marquée comme incomplète par votre conseiller.ère Audiens.
                        Il vous est nécessaire de joindre de nouveaux documents à votre demande.<br>
                        <em v-if="currentAccord.refused_message"> Raisons du refus : {{currentAccord.refused_message}}</em>
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-danger" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                  <div v-else-if="currentAccord.state == '6MREFUSED'">
                    <p>
                      Votre demande d'accord de principe {{currentYear}} a été refusée par votre conseiller.ère Audiens.<br>
                      <em v-if="currentAccord.refused_message"> Raisons du refus : {{currentAccord.refused_message}}</em>
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-danger" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                  <div v-else-if="currentAccord.state == '7MVALIDATED'">
                    <p>
                      Votre demande d'accord de principe a été validée et est valable jusqu'au 31 décembre {{currentYear}}.<br>
                      Attention, les frais de garde doivent être transmis dans un <u>délai maximal de 3 mois après leur règlement</u> 
                      (par exemple : la demande de remboursement des frais de garde de janvier 2023 pourra être formulée sur la plateforme au plus tard en avril 2023).
                    </p>
                    <b-button @click="$router.push('/accord/' + currentAccord.id)" pill block variant="outline-success" class="mt-2">
                      Accéder à votre demande d'accord
                    </b-button>
                  </div>
                </div>

                <div v-else>
                  <p>
                    Veuillez ajouter au moins un enfant éligibile (moins de 4 ans) à votre profil pour pouvoir faire une demande d'accord de principe
                  </p>
                </div>


                <hr>


                <h2>Vos accords de principes pour les années passées</h2>

                <div v-if="lastAccords.length === 0">
                  <p>
                    Vous n'avez aucun accord pour les années passées
                  </p>
                </div>
                <div v-else>
                  <div v-for="accord in lastAccords" v-bind:key="accord.id" class="mt-3">
                    <strong>{{accord.year}} -
                      <span v-if="accord.state == '1CREATED'" class="text-info">
                        Accord de principe créé, demande de validation à réaliser<v-icon class="ml-1" name="edit" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '2ONGOING'" class="text-secondary">
                        En cours de validation par votre conseiller.ère Audiens <v-icon class="ml-1" name="dot-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '4CREFUSED' || accord.state == '5CVALIDATED'" class="text-secondary">
                        Pris en charge par votre conseiller.ère Audiens <v-icon class="ml-1" name="dot-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '3INCOMPLET'" class="text-danger">
                        Dossier à compléter, veuillez corriger vos informations et réactualiser votre demande <v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '3IMPOTINCOMPLET'" class="text-danger">
                        Dossier à compléter, veuillez ajouter des documents en plus de votre avis d'imposition  <v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '6MREFUSED'" class="text-danger">
                        Refusé<v-icon class="ml-1" name="times-circle" scale="1"/>
                      </span>
                      <span v-else-if="accord.state == '7MVALIDATED'" class="text-success">
                        Validé <v-icon class="ml-1" name="check-circle" scale="1"/>
                      </span>
                    </strong>

                    <div v-if="accord.state == '1CREATED'">
                      <p> Votre demande d'accord de principe {{accord.year}} a bien été créée. Vous pouvez y accéder, charger vos documents puis
                        faire une demande de validation de votre accord
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                    <div v-else-if="accord.state == '2ONGOING' || accord.state == '4CREFUSED' || accord.state == '5CVALIDATED'">
                      <p> Votre demande d'accord de principe {{accord.year}} est en cours de validation par votre conseiller.ère Audiens.
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                    <div v-else-if="accord.state == '3INCOMPLET'">
                      <p>
                        Votre demande d'accord de principe {{accord.year}} a été marquée comme incomplète par votre conseiller.ère Audiens. <br>
                        <em v-if="accord.refused_message"> Raisons du refus : {{accord.refused_message}}</em>
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                    <div v-else-if="accord.state == '3IMPOTINCOMPLET'">
                      <p> Votre demande d'accord de principe {{accord.year}} a été marquée comme incomplète par votre conseiller.ère Audiens.
                          Il vous est nécessaire de joindre de nouveaux documents à votre demande.<br>
                          <em v-if="accord.refused_message"> Raisons du refus : {{accord.refused_message}}</em>
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                    <div v-else-if="accord.state == '6MREFUSED'">
                      <p>
                        Votre demande d'accord de principe {{accord.year}} a été refusée par votre conseiller.ère Audiens.<br>
                        <em v-if="accord.refused_message"> Raisons du refus : {{accord.refused_message}}</em>
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                    <div v-else-if="accord.state == '7MVALIDATED'">
                      <p>
                        Votre demande d'accord de principe {{accord.year}} a été validée. Vous pouvez faire des demandes de remboursement
                        pour les mois de l'année {{accord.year}}.<br>
                        Attention, les frais de garde doivent être transmis dans un <u>délai maximal de 3 mois après leur règlement</u> 
                        (par exemple : la demande de remboursement des frais de garde de janvier 2023 pourra être formulée sur la plateforme au plus tard en avril 2023).
                      </p>
                      <b-button @click="$router.push('/accord/' + accord.id)" pill block variant="outline-secondary" class="mt-1" size="sm">
                        Accéder à votre demande d'accord
                      </b-button>
                    </div>
                  </div>
                </div>

              </b-tab>


              <b-tab >
                <template slot="title">
                  <span class="h4">Mes demandes de remboursement</span>
                </template>
                <div v-if="newRemboursementYearArray.length > 0">
                  <b-button
                    pill block variant="outline-info" class="mt-1" size="lg"
                    @click="showCreateRemboursementModal">
                    Faire une nouvelle demande de remboursement
                  </b-button>
                </div>
                <div v-else>
                  Vous n'avez pas encore d'accord(s) de principe vous permettant de déposer une demande de
                  remboursement, veuillez d'abord faire une demande d'accord de principe
                </div>
                <h2>Vos remboursements</h2>
                <p class="mt-3">
                  <span> Montant total remboursé en {{currentYear}} : </span>
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantCurrentYear).toFixed(2))}} €</strong> (frais de garde) /
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantFraisDeplacementCurrentYear).toFixed(2))}} €</strong> (frais supplémentaires)

                </p>
                <p>
                  <span> Montant total remboursé en {{currentYear-1}} : </span>
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantLastYear).toFixed(2))}} €</strong> (frais de garde) /
                  <strong>{{formatThousandSeparatorNumber(parseFloat(totalMontantFraisDeplacementLastYear).toFixed(2))}} €</strong> (frais supplémentaires)

                </p>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light" hover
                  :fields="remboursementsRenderFields"
                  :items="beneficiaire.remboursements"
                  @row-clicked="goToRemboursementPage"
                  >
                  <template v-slot:cell(month)="data">
                    {{monthRender(data.item.month)}}
                  </template>
                  <template v-slot:cell(state)="data">
                    {{beneficiaireRemboursementStateRender(data.item.state)}}
                  </template>
                  <template v-slot:cell(montant)="data">
                    <span v-if="data.item.state == '7MVALIDATED' || data.item.state == '8PAIEMENT'">
                      {{data.item.montant}} €
                      <span v-if="data.item.montant_frais_deplacement > 0">
                        <br>
                        <small>{{data.item.montant_frais_deplacement}} € de frais supplémentaires</small>
                      </span>
                    </span>
                  </template>
                </b-table>


              </b-tab>

            </b-tabs>


          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { APIBeneficiaireConnected } from '@/api/APIBeneficiaireConnected'
import renderMixins from '@/mixins/renderMixins'
import { dateOperations } from '@/mixins/utilMixins'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import formTitleComponent from '@/components/formTitleComponent'

const childrenComponent = () => import('@/components/beneficiaire/ChildrenComponent')


import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiBeneficiaireConnectedService = new APIBeneficiaireConnected()

export default {
  name: 'BeneficiaireDashboard',
  components: {
    formTitleComponent,
    Loading,
    childrenComponent
  },
  mixins: [
    renderMixins,
    validationMixin,
    dateOperations
  ],
  data: function () {
    const now = new Date()
    const currentYear = now.getFullYear()
    return {

      tabsIndex: 0,

      // ------- BENEFICIAIRE --------
      beneficiaire: {
        user: {
          first_name: '',
          last_name: '',
          username: '',
        },
        activite: '',
        metier: '',
        address: '',
        birthdate: '',
        city: '',
        code_postal: '',
        other_first_names: '',
        muna: '',
        memo_message: '',
        phone_number: '',
        other_phone_number: '',
        situation: '',
        conjoint_first_name: '',
        conjoint_last_name: '',
        is_conjoint_intermittent: false,
        refused_message: '',
        children: [],
        accords: [],
        remboursements: []
      },
      beneficiaireLoading: false,

      // ------  ACCORD -----------
      currentYear: currentYear,
      currentAccord : null,
      lastAccords: [],

      createAccordButtonText: "Faire une demande d'accord",
      createAccordButtonStyle: 'outline-dark',
      createAccordInProcess: false,



      // ------ REMBOURSEMENTS -----
      newRemboursementYearArray: [''],
      newRemboursementMonthArray: [
        { text: '', value: '' },
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Août', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 }
      ],

      newRemboursementYear: '',
      newRemboursementMonth: '',
      newRemboursementChildren: [],
      newRemboursementIsCreche: false,
      newRemboursementIsGadPajemploi: false,
      newRemboursementIsGadCesu: false,
      newRemboursementIsGadStructure: false,
      newRemboursementIsPair: false,

      createRemboursementButtonText: "Faire votre demande de remboursement",
      createRemboursementButtonStyle: 'outline-dark',
      createRemboursementInProcess: false,

      totalMontantCurrentYear: 0,
      totalMontantFraisDeplacementCurrentYear: 0,
      totalMontantFraisDeplacementLastYear: 0,
      totalMontantLastYear: 0,

      remboursementsRenderFields: [
        { key: "month", label: "Mois concerné", tdClass: 'ui-helper-center', sortable: true},
        { key: "year", label: "Année concernée", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center' },
        { key: "montant", label: "Montant remboursé", tdClass: 'ui-helper-center' },
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.beneficiaireLoading) {
        return true
      }
      return false
    },
    isAtLeastOneTypeOfGarde () {
      if (!this.newRemboursementIsCreche && !this.newRemboursementIsGadPajemploi && !this.newRemboursementIsGadCesu && !this.newRemboursementIsGadStructure && !this.newRemboursementIsPair) {
        return false
      }
      return true
    },
    newRemboursementChildrenIdArray () {
      var final_array = []
      for (var i = 0; i < this.newRemboursementChildren.length; i++) {
        final_array.push(
          this.newRemboursementChildren[i].value,
        )
      }
      return final_array
    },
    activeChildren () {
      if (this.beneficiaire.children.length == 0) {
        return []
      }
      else {
        var final_array = []
        for (var i = 0; i < this.beneficiaire.children.length; i++) {
          if (this.beneficiaire.children[i].is_active) {
            final_array.push(
              {
                'id': this.beneficiaire.children[i].id,
                'first_name': this.beneficiaire.children[i].first_name,
                'last_name': this.beneficiaire.children[i].last_name,
                'birthdate': this.beneficiaire.children[i].birthdate,
              }
            )
          }
        }
        return final_array
      }
    },
    eligibleChildren () {
      return this.beneficiaire.children.reduce((children, child) => {
        if (this.calculateAge(child.birthdate) < 4 && child.is_active) {
          children.push(child)
        }
        return children
      }, [])
    },
    newRemboursementChildrenSelectOptions () {

      if (this.newRemboursementYear && this.newRemboursementMonth) {
        return this.beneficiaire.children.reduce((children, child) => {
          const lastDateOfMonth = new Date(this.newRemboursementYear, this.newRemboursementMonth, 0)

          const birthdate = new Date(child.birthdate)
          if (child.is_active && this.ageInferiorToLimitDate(birthdate, 4, lastDateOfMonth)) {
            children.push(
              {
                'label': child.first_name + ' ' + child.last_name,
                'value': child.id,
              }
            )
          }
          return children
        }, [])
      }
      else {
        return []
      }
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getConnectedBeneficiaire()
  },
  validations: {
    newRemboursementYear: {
      required
    },
    newRemboursementMonth: {
      required,
    },
    newRemboursementChildren: {
      required,
      isNotEmptyArray (value) {
        if (value.length > 0) {
          return true
        }
        else {
          return false
        }
      }
    },
  },
  watch: {
    beneficiaire: function (newBeneficiaire) {

      // Calculate the year available
      this.currentAccord = null
      this.lastAccords = []
      this.newRemboursementYearArray = ['']

      for (var i = 0; i < newBeneficiaire['accords'].length; i++) {
        const temp_accord = newBeneficiaire['accords'][i]
        // Accord
        if (temp_accord['year'] === this.currentYear) {
          this.currentAccord = temp_accord
        }
        else {
          this.lastAccords.push(temp_accord)
        }

        // Remboursement
        if (temp_accord['year'] === this.currentYear && temp_accord['state'] === '7MVALIDATED') {
          if (!this.newRemboursementYearArray.includes(this.currentYear)) {
            this.newRemboursementYearArray.push(this.currentYear)
          }
        }

        if (temp_accord['year'] === this.currentYear - 1 && temp_accord['state'] === '7MVALIDATED') {
           if (!this.newRemboursementYearArray.includes(this.currentYear-1) && this.currentYear - 1 !== 2022) { // manual remove 2022
              this.newRemboursementYearArray.push(this.currentYear-1)
            }
         }
      }


      // Calculate the montant total alloué
      this.totalMontantCurrentYear = 0
      this.totalMontantLastYear = 0
      this.totalMontantFraisDeplacementCurrentYear = 0
      this.totalMontantFraisDeplacementLastYear = 0

      for (var j = 0; j < newBeneficiaire.remboursements.length; j++) {
        // Accord
        if (newBeneficiaire.remboursements[j].year === this.currentYear && (newBeneficiaire.remboursements[j].state === '7MVALIDATED' || newBeneficiaire.remboursements[j].state === '8PAIEMENT')) {
          this.totalMontantCurrentYear = this.totalMontantCurrentYear  + parseFloat(newBeneficiaire.remboursements[j].montant)
          this.totalMontantFraisDeplacementCurrentYear = this.totalMontantFraisDeplacementCurrentYear  + parseFloat(newBeneficiaire.remboursements[j].montant_frais_deplacement)
        }

        else if (newBeneficiaire.remboursements[j].year === this.currentYear - 1 && (newBeneficiaire.remboursements[j].state === '7MVALIDATED' || newBeneficiaire.remboursements[j].state === '8PAIEMENT')){
          this.totalMontantLastYear = this.totalMontantLastYear  + parseFloat(newBeneficiaire.remboursements[j].montant)
          this.totalMontantFraisDeplacementLastYear = this.totalMontantFraisDeplacementLastYear  + parseFloat(newBeneficiaire.remboursements[j].montant_frais_deplacement)
        }
      }
    },

    newRemboursementYear: function (newValue) {
      this.newRemboursementChildren = []
      this.newRemboursementMonthArray = [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Août', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 }
      ]
      const final_new_remboursement_month = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
      ]

      var toRemoveMonthsSet = new Set()

      const now = new Date()
      const currentMonth = now.getMonth() + 1
      const currentYear = now.getFullYear()

      for (var i = 0; i < final_new_remboursement_month.length; i++) {
        const tempMonth = final_new_remboursement_month[i]
        var isRemoved = false


        // Check if it's in the futur
        if (newValue == currentYear && currentMonth < tempMonth ) {
          toRemoveMonthsSet.add(final_new_remboursement_month[i])
          //this.newRemboursementMonthArray.splice( this.newRemboursementMonthArray.indexOf(final_new_remboursement_month[i]), 1 );
          isRemoved = true
        }
        if (isRemoved) {
          continue
        }

        // Check if it's more than 3 month before
        const stakeDate = new Date(newValue, tempMonth - 1, 1)
        var nb_months_diff = this.monthDiff(stakeDate, now)
        if (nb_months_diff > 3) {
          toRemoveMonthsSet.add(final_new_remboursement_month[i])
          isRemoved = true
        }
        if (isRemoved) {
          continue
        }

        // Check if there is already a remboursements
        for (var j = 0; j < this.beneficiaire['remboursements'].length; j++) {
          const remb = this.beneficiaire['remboursements'][j]
          if (remb.year == newValue && remb.month == tempMonth) {
            toRemoveMonthsSet.add(final_new_remboursement_month[i])
            isRemoved = true
            break
          }
        }
        if (isRemoved) {
          continue
        }

        // Check if the accord can enable to have a remboursement
        var chosenYearAccord = null
        var nextYearAccord = null
        for (var k = 0; k < this.beneficiaire['accords'].length; k++) {
          const accord = this.beneficiaire['accords'][k]
          if (accord.year == newValue && accord.state == '7MVALIDATED') {
            chosenYearAccord = this.beneficiaire['accords'][k]
            break
          }
          if (accord.year == newValue + 1 && accord.state == '7MVALIDATED') {
            nextYearAccord = this.beneficiaire['accords'][k]
          }
        }
        if (chosenYearAccord) {
          var firstAskValidationDateMonth = this.$dayjs(chosenYearAccord.first_ask_validation_date).month() + 1 // entre 1 et 12
          // We remove month before date of first ask validation of accord for current year
          if (tempMonth < firstAskValidationDateMonth) {
            toRemoveMonthsSet.add(final_new_remboursement_month[i])
          }
        }
        else if (nextYearAccord) {
          var firstAskValidationDateSixMonthBefore = this.$dayjs(nextYearAccord.first_ask_validation_date).month() + 1 - 6
          if (firstAskValidationDateSixMonthBefore < 1) {
            firstAskValidationDateSixMonthBefore = 12 + firstAskValidationDateSixMonthBefore
          }
          if (tempMonth < firstAskValidationDateSixMonthBefore) {
            toRemoveMonthsSet.add(final_new_remboursement_month[i])
          }
        }
        else {
          toRemoveMonthsSet.add(final_new_remboursement_month[i])
        }
      }

      for( var l = this.newRemboursementMonthArray.length; l--;){
        if ( toRemoveMonthsSet.has(this.newRemboursementMonthArray[l].value)) this.newRemboursementMonthArray.splice(l, 1);
      }

    },

    newRemboursementMonth () {
      this.newRemboursementChildren = []
    }
  },

  methods: {
    // ---------------------- BENEFICIAIRES -----------------------
    getConnectedBeneficiaire () {
      this.beneficiaireLoading = true
      apiBeneficiaireConnectedService.getConnectedBeneficiaire(this.token)
        .then((result) => {
          this.beneficiaire = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.beneficiaireLoading = false
        })
    },

    // -------------------- ACCORD DE PRINCIPE -------------------
    createAccord () {
      this.createAccordInProcess = true
      apiBeneficiaireConnectedService.createAccord(this.token, this.currentYear)
      .then((result) => {
        this.$router.push('/accord/' + result.data.id)
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.createAccordInProcess = false
      })
    },

    // -------------------- REMBOURSEMENT -------------------
    createRemboursement () {
      this.createRemboursementInProcess = true
      apiBeneficiaireConnectedService.createRemboursement(
        this.token, this.newRemboursementMonth, this.newRemboursementYear,
        this.newRemboursementChildrenIdArray, this.newRemboursementIsCreche,
        this.newRemboursementIsGadPajemploi, this.newRemboursementIsGadCesu,
        this.newRemboursementIsGadStructure, this.newRemboursementIsPair
      )
      .then((result) => {
        this.$router.push('/remboursement/' + result.data.id)
      })
      .catch(() => {
        this.$refs.createRemboursementErrorModal.show()
        this.$refs.createRemboursementModal.hide()
      })
      .finally(() => {
        this.createRemboursementInProcess = false
      })
    },


    // ----------------- RESET FUNCTION ------------------------
    resetParameters () {
      this.currentAccord  = null
      this.lastAccords = []

      this.newRemboursementYearArray = []
      this.newRemboursementMonthArray = [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Août', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 }
      ]

      this.newRemboursementYear = ''
      this.newRemboursementMonth = ''
    },


    // ----------------- MODAL FUNCTION ------------------------
    showCreateRemboursementModal ( ) {
      this.$refs.createRemboursementModal.show()
    },

    // --------------- ROUTER FUNCTION -----------------------
    goToRemboursementPage(item) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.$router.push('/remboursement/' + item['id'])
    },
  }
}
</script>
<style>
.option-button {
    height:100%;
}

.media-object {
    height: 100px;
}
</style>
